<template>
    <div>
        <full-overlay :show="loading" />
        <b-modal title="Описание" v-model="show" hide-footer size="lg">
            <b-form @submit.prevent="send">
                <b-dropdown text="Добавить блок">
                    <b-dropdown-item
                        v-for="t in types"
                        :key="t.id"
                        @click="add(t.id)"
                    >
                        <b-icon :icon="t.icon" />
                        <span class="ml-2">{{ t.title }}</span>
                    </b-dropdown-item>
                </b-dropdown>
                <b-modal
                    v-model="modal.show"
                    hide-footer
                    :title="getTypeById(modal.type).title"
                    size="lg"
                    no-enforce-focus
                >
                    <b-form @submit.prevent="saveContent">
                        <template v-if="modal.type == 1 || modal.type == 2">
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Текст">
                                        <b-form-input
                                            v-model="modal.data.ru"
                                            required
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="[En] Текст">
                                        <b-form-input v-model="modal.data.en" />
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Выравнивание">
                                        <b-form-select
                                            :options="align"
                                            v-model="modal.data.align"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col></b-col>
                            </b-form-row>
                        </template>
                        <template v-if="modal.type == 3">
                            <b-form-group label="Текст">
                                <div class="editor-wrap">
                                    <editor
                                        :editor="editor"
                                        v-model="modal.data.ru"
                                        :config="config"
                                    />
                                </div>
                            </b-form-group>
                            <b-form-group label="[En] Текст">
                                <editor
                                    :editor="editor"
                                    v-model="modal.data.en"
                                    :config="config"
                                />
                            </b-form-group>
                        </template>
                        <template v-if="modal.type == 4">
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Ссылка">
                                        <b-form-input
                                            v-model="modal.data.link"
                                            required
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Текст">
                                        <b-form-input
                                            v-model="modal.data.ru"
                                            required
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="[En] Текст">
                                        <b-form-input v-model="modal.data.en" />
                                    </b-form-group>
                                </b-col>
                            </b-form-row>
                            <b-form-row>
                                <b-col>
                                    <b-form-group label="Выравнивание">
                                        <b-form-select
                                            :options="align"
                                            v-model="modal.data.align"
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col></b-col>
                                <b-col></b-col>
                            </b-form-row>
                        </template>
                        <template v-if="modal.type == 5">
                            <b-form-group label="Загрузка файла">
                                <b-form-file
                                    v-model="modal.data.file"
                                    accept="image/jpeg, image/png"
                                />
                            </b-form-group>
                            <b-form-group
                                label="Текущее изображение"
                                v-if="modal.id > 0"
                            >
                                <div class="modal-image">
                                    <img
                                        :src="`${mediaUrl}${modal.data.image}`"
                                    />
                                    <div class="mt-3">
                                        {{ modal.data.name }}
                                    </div>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-if="modal.type == 6">
                            <b-form-group label="Загрузка файла">
                                <b-form-file
                                    v-model="modal.data.file"
                                    accept="video/mp4"
                                />
                            </b-form-group>
                            <b-form-group
                                label="Текущее видео"
                                v-if="modal.id > 0"
                            >
                                <div class="modal-image">
                                    <video
                                        :src="`${mediaUrl}${modal.data.video}`"
                                        controls
                                    />
                                    <div class="mt-3">
                                        {{ modal.data.name }}
                                    </div>
                                </div>
                            </b-form-group>
                        </template>
                        <template v-if="modal.type == 7">
                            <b-form-group label="Загрузка файлов">
                                <b-form-file
                                    accept="image/jpeg, image/png"
                                    multiple
                                    ref="formFile"
                                    @change="
                                        updateFormFileList($event, modal.type)
                                    "
                                />
                            </b-form-group>
                            <draggable
                                class="list mt-3"
                                v-model="modal.data.list"
                            >
                                <div
                                    v-for="l in modal.data.list"
                                    :key="l.id"
                                    class="item border p-2"
                                >
                                    <div
                                        v-if="l.image"
                                        class="
                                            item__content item__content--center
                                        "
                                    >
                                        <img :src="`${mediaUrl}${l.image}`" />
                                        <span class="ml-3">
                                            {{ l.name }}
                                        </span>
                                    </div>
                                    <template v-else>
                                        {{ l.file.name }}
                                    </template>
                                    <b-button
                                        class="ml-auto"
                                        variant="danger"
                                        title="Удалить"
                                        @click="removeFormFile(l.id)"
                                    >
                                        <b-icon icon="trash" />
                                    </b-button>
                                </div>
                            </draggable>
                        </template>
                        <template v-if="modal.type == 8">
                            <b-form-group label="Загрузка файлов">
                                <b-form-file
                                    multiple
                                    ref="formFile"
                                    @change="
                                        updateFormFileList($event, modal.type)
                                    "
                                />
                            </b-form-group>
                            <draggable
                                class="list mt-3"
                                v-model="modal.data.list"
                            >
                                <div
                                    v-for="l in modal.data.list"
                                    :key="l.id"
                                    class="item border p-2"
                                >
                                    <div
                                        class="
                                            item__content item__content--docs
                                        "
                                    >
                                        <b-link
                                            v-if="l.saved"
                                            :href="`${mediaUrl}${l.saved}`"
                                            target="_blank"
                                        >
                                            {{ l.name }}
                                        </b-link>
                                        <span v-else>{{ l.file.name }}</span>
                                        <div>
                                            <b-form-input
                                                placeholder="Отображаемое имя"
                                                v-model="l.showName.ru"
                                            />
                                            <b-form-input
                                                placeholder="[En] Отображаемое имя"
                                                v-model="l.showName.en"
                                            />
                                        </div>
                                        <b-form-checkbox v-model="l.red">
                                            Красный фон
                                        </b-form-checkbox>
                                    </div>
                                    <b-button
                                        class="ml-auto"
                                        variant="danger"
                                        title="Удалить"
                                        @click="removeFormFile(l.id)"
                                    >
                                        <b-icon icon="trash" />
                                    </b-button>
                                </div>
                            </draggable>
                        </template>
                        <template v-if="modal.type == 9">
                            <b-form-group label="HTML">
                                <prism-editor
                                    class="my-editor"
                                    v-model="modal.data.ru"
                                    :highlight="highlighter"
                                    line-numbers
                                />
                            </b-form-group>
                            <b-form-group label="[En] HTML">
                                <prism-editor
                                    class="my-editor"
                                    v-model="modal.data.en"
                                    :highlight="highlighter"
                                    line-numbers
                                />
                            </b-form-group>
                        </template>
                        <b-button type="submit" class="mt-3">
                            Сохранить
                        </b-button>
                    </b-form>
                </b-modal>
                <draggable class="list mt-3" v-model="list">
                    <div v-for="l in list" :key="l.id" class="item border p-2">
                        <b-icon
                            :icon="getTypeById(l.type).icon"
                            :title="getTypeById(l.type).title"
                        />
                        <div
                            class="item__content"
                            :class="{
                                'item__content--center': l.type != 3,
                            }"
                        >
                            <template v-if="l.type == 1 || l.type == 2">
                                {{ l.data.ru }}
                            </template>
                            <div v-if="l.type == 3" v-html="l.data.ru" />
                            <b-link v-if="l.type == 4" :href="l.data.link">
                                {{ l.data.ru }}
                            </b-link>
                            <template v-if="l.type == 5">
                                <template v-if="l.data.file">
                                    {{ l.data.file.name }}
                                </template>
                                <template v-else>
                                    <img :src="`${mediaUrl}${l.data.image}`" />
                                    <span class="ml-3">{{ l.data.name }}</span>
                                </template>
                            </template>
                            <template v-if="l.type == 6">
                                <template v-if="l.data.file">
                                    {{ l.data.file.name }}
                                </template>
                                <template v-else>
                                    {{ l.data.name }}
                                </template>
                            </template>
                            <template v-if="l.type == 7 || l.type == 8">
                                Файлов: {{ l.data.list.length }}
                            </template>
                            <div v-if="l.type == 9" v-text="l.data.ru" />
                        </div>
                        <b-button-group class="ml-auto">
                            <b-button @click="edit(l.id)" title="Изменить">
                                <b-icon icon="pencil" />
                            </b-button>
                            <b-button
                                variant="danger"
                                title="Удалить"
                                @click="remove(l.id)"
                            >
                                <b-icon icon="trash" />
                            </b-button>
                        </b-button-group>
                    </div>
                </draggable>
                <b-button class="mt-3" type="submit">Сохранить</b-button>
            </b-form>
        </b-modal>
        <file-uploader ref="uploader" />
    </div>
</template>

<style lang="scss" scoped>
/* required class */
.my-editor::v-deep {
    /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
    background: #2d2d2d;
    color: #ccc;

    /* you must provide font-family font-size line-height. Example: */
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    pre {
        color: inherit;
    }
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
    outline: none;
}
.list {
    overflow: auto;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.item {
    display: flex;
    gap: 10px;
    align-items: center;
    &__content {
        height: 38px;
        overflow: hidden;
        &--center {
            display: flex;
            align-items: center;
        }
        &--docs {
            height: auto;
            display: grid;
            grid-template-columns: 150px 350px 1fr;
            gap: 10px;
            align-items: center;
        }
        img {
            height: 100%;
        }
    }
}
.modal-image {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    img {
        max-width: 600px;
        max-height: 500px;
    }
}
</style>

<script>
import Draggable from "vuedraggable";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";
import "@a/ckeditor.scss";
import FileUploader from "@c/FileUploader.vue";
import FullOverlay from "@c/FullOverlay.vue";

// import Prism Editor
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles

export default {
    name: "ContentEditor",
    components: {
        Draggable,
        Editor: CKEditor.component,
        FileUploader,
        FullOverlay,
        PrismEditor,
    },
    props: {
        getUrl: {
            type: String,
            required: true,
        },
        sendUrl: {
            type: String,
            required: true,
        },
        mediaUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            show: false,
            newId: 0,
            id: 0,
            list: [],
            modal: this.getModal(),
            editor: ClassicEditor,
            config: {
                language: "ru",
                baseFloatIndex: 9001,
                toolbar: [
                    "heading",
                    "bold",
                    "italic",
                    "blockQuote",
                    "link",
                    "numberedList",
                    "bulletedList",
                    "insertTable",
                ],
            },
        };
    },
    computed: {
        align: () => [
            { value: 0, text: "Не задано" },
            { value: 1, text: "Левый край" },
            { value: 2, text: "Центр" },
            { value: 3, text: "Правый край" },
        ],
        types: () => [
            {
                id: 1,
                icon: "type-h1",
                title: "Заголовок",
            },
            {
                id: 2,
                icon: "type-h2",
                title: "Подзаголовок",
            },
            {
                id: 3,
                icon: "type",
                title: "Текст",
            },
            {
                id: 4,
                icon: "link",
                title: "Ссылка",
            },
            {
                id: 5,
                icon: "image",
                title: "Изображение",
            },
            {
                id: 6,
                icon: "camera-video",
                title: "Видео",
            },
            {
                id: 7,
                icon: "images",
                title: "Галерея",
            },
            {
                id: 8,
                icon: "paperclip",
                title: "Документы",
            },
            {
                id: 9,
                icon: "journal",
                title: "HTML",
            },
        ],
    },
    methods: {
        highlighter: (code) => highlight(code, languages.js),
        updateFormFileList({ target: { files } }, type) {
            for (let f of files) {
                const obj = {
                    id: --this.newId,
                    file: f,
                };
                if (type == 8) {
                    obj.red = false;
                    obj.showName = {
                        ru: "",
                        en: "",
                    };
                }
                this.modal.data.list.push(obj);
            }
            this.$refs.formFile.reset();
        },
        async open(id) {
            this.id = id;
            this.loading = true;
            const url = this.getUrl.replace(":id", id);
            const { list } = await this.$fetchAdmin(url).then((r) => r.json());
            this.list = list;
            this.show = true;
            this.loading = false;
        },
        async prepareData() {
            const copy = this.list.map((l) => {
                let c = Object.assign(
                    { ...l },
                    {
                        data: { ...l.data },
                    }
                );
                if (l.type == 7 || l.type == 8) {
                    c.data.list = c.data.list.map((l) => ({ ...l }));
                }
                return c;
            });
            const needUpload = [];
            for (let c of copy) {
                if ((c.type == 5 || c.type == 6) && c.data.file) {
                    c.data.file.id = c.id;
                    needUpload.push(c.data.file);
                    delete c.data.file;
                }
                if (c.type == 7 || c.type == 8) {
                    for (let l of c.data.list) {
                        if (l.file) {
                            l.file.id = l.id;
                            needUpload.push(l.file);
                            delete l.file;
                        }
                    }
                }
            }
            if (needUpload.length) {
                const files = await this.$refs.uploader.upload(needUpload);
                for (let c of copy) {
                    switch (c.type) {
                        case 5:
                        case 6:
                            for (let f of files) {
                                if (f.id == c.id) {
                                    c.data = {
                                        tmp: f.tmp,
                                        name: f.name,
                                        size: Math.ceil(f.size / 1024 / 1024),
                                        type: f.type.split("/")[1],
                                    };
                                }
                            }
                            break;
                        case 7:
                        case 8:
                            for (let l of c.data.list) {
                                for (let f of files) {
                                    if (f.id == l.id) {
                                        l.tmp = f.tmp;
                                        l.name = f.name;
                                        l.size = Math.ceil(
                                            f.size / 1024 / 1024
                                        );
                                        l.type = f.type.split("/")[1];
                                    }
                                }
                            }
                            break;
                    }
                }
            }
            for (let c of copy) {
                if (c.type == 7 || c.type == 8) {
                    c.data.list = c.data.list.map((l) => {
                        delete l.id;
                        return l;
                    });
                }
            }
            return copy;
        },
        async send() {
            try {
                const list = await this.prepareData();
                this.loading = true;
                const url = this.sendUrl.replace(":id", this.id);
                await this.$fetchAdmin(url, {
                    method: "POST",
                    body: JSON.stringify(list),
                }).then((r) => r.json());
                this.loading = false;
                this.show = false;
            } catch (e) {
                this.$bvToast.toast(e.message, {
                    variant: "danger",
                });
            }
        },
        getTypeById(id) {
            for (let t of this.types) {
                if (t.id == id) {
                    return t;
                }
            }
            return {
                id: 0,
                title: "",
                icon: "",
            };
        },
        async remove(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            for (let i = 0; i < this.list.length; i++) {
                if (this.list[i].id == id) {
                    this.list.splice(i, 1);
                    break;
                }
            }
        },
        async removeFormFile(id) {
            const st = await this.$bvModal.msgBoxConfirm(
                "Подтвердите удаление"
            );
            if (!st) {
                return false;
            }
            for (let i = 0; i < this.modal.data.list.length; i++) {
                if (this.modal.data.list[i].id == id) {
                    this.modal.data.list.splice(i, 1);
                    break;
                }
            }
        },
        saveContent() {
            if (this.modal.id == 0) {
                this.list.push({
                    id: --this.newId,
                    type: this.modal.type,
                    data: this.modal.data,
                });
            } else {
                for (let l of this.list) {
                    if (l.id == this.modal.id) {
                        l.data = this.modal.data;
                        break;
                    }
                }
            }
            this.modal.show = false;
        },
        getModal: () => ({
            id: 0,
            show: false,
            data: {},
            type: 0,
        }),
        add(type) {
            this.modal = this.getModal();
            this.modal.type = type;
            switch (type) {
                case 1:
                case 2:
                    this.modal.data = {
                        ru: "",
                        en: "",
                        align: 0,
                    };
                    break;
                case 3:
                case 9:
                    this.modal.data = {
                        ru: "",
                        en: "",
                    };
                    break;
                case 4:
                    this.modal.data = {
                        link: "",
                        ru: "",
                        en: "",
                        align: 0,
                    };
                    break;
                case 5:
                case 6:
                    this.modal.data = {
                        file: null,
                    };
                    break;
                case 7:
                case 8:
                    this.modal.data = {
                        list: [],
                    };
                    break;
            }
            this.modal.show = true;
        },
        edit(id) {
            this.modal = this.getModal();
            this.modal.id = id;
            for (let l of this.list) {
                if (l.id == id) {
                    this.modal.type = l.type;
                    const data = { ...l.data };
                    if (l.type == 5 || l.type == 6) {
                        data.file = null;
                    }
                    if (l.type == 7 || l.type == 8) {
                        data.list = data.list.map((l) =>
                            Object.assign({ ...l }, { id: --this.newId })
                        );
                    }
                    this.modal.data = data;
                    break;
                }
            }
            this.modal.show = true;
        },
    },
};
</script>