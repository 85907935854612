<template>
    <b-modal
        title="Загрузка"
        hide-footer
        hide-header-close
        no-close-on-esc
        no-close-on-backdrop
        v-model="show"
    >
        <b-form-group label="Всего файлов">
            <b-progress :max="total">
                <b-progress-bar :value="current">
                    <span>{{ current }} / {{ total }}</span>
                </b-progress-bar>
            </b-progress>
        </b-form-group>
        <b-form-group label="Текущий файл">
            <b-progress :value="progress" />
        </b-form-group>
    </b-modal>
</template> 

<script>
import Flow from "@flowjs/flow.js";
export default {
    name: "FileUploader",
    data: () => ({
        total: 0,
        current: 0,
        progress: 0,
        show: false,
    }),
    methods: {
        async upload(filesToUpload) {
            this.total = filesToUpload.length;
            this.current = 0;
            this.progress = 0;
            this.show = true;
            return new Promise((resolve, reject) => {
                let error = false;
                const uploader = new Flow({
                    target: `${this.$store.state.api}/admin/upload`,
                    allowDuplicateUploads: true,
                    withCredentials: true,
                });
                uploader.on("error", () => {
                    error = true;
                    uploader.cancel();
                });
                uploader.on("fileSuccess", ({ file }, msg) => {
                    this.current += 1;
                    for (let f of filesToUpload) {
                        if (f.id == file.id) {
                            f.tmp = msg;
                        }
                    }
                });
                uploader.on("fileProgress", (file) => {
                    this.progress = file.progress() * 100;
                });
                uploader.on("complete", () => {
                    this.show = false;
                    if (error) {
                        reject(new Error("Ошибка загрузки файлов"));
                        return false;
                    }
                    resolve(filesToUpload);
                });
                uploader.addFiles(filesToUpload);
                uploader.upload();
            });
        },
    },
};
</script>